@tailwind base;
@tailwind components;
@tailwind utilities;

.disabled {
  pointer-events: none;
  opacity: 0.4;
  .absolute {
    display: none;
  }
}

:root {
  --color-blue-400: #60a5fa;
  --color-blue-500: #0ea5e9;
  --color-pink-400: #f472b6;
  --color-gray-400: #9ca3af;
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-zinc-600: #52525b;
  --color-zinc-700: #3f3f46;
  --color-zinc-800: #27272a;
  --color-purple-400: #c084fc;
  --color-purple-500: #a855f7;
  --color-purple-600: #9333ea;
  --color-green-400: #4ade80;
}
.text-pink {
  color: var(--color-pink-400);
}
.text-green {
  color: var(--color-green-400);
}
.text-blue {
  color: var(--color-blue-400);
}
.text-light-blue { 
  color: var(--color-blue-500);
}
.text-gray {
  color: var(--color-gray-400);
}
.text-gray-500 {
  color: var(--color-gray-500);
}
.text-purple {
  color: var(--color-purple-400);
}
.text-purple-500 {
  color: var(--color-purple-500);
}
.text-purple-600 {
  color: var(--color-purple-600);
}
.bg-light-blue { 
  background-color: var(--color-blue-500);
}
.bg-zinc-600 { 
  background-color: var(--color-zinc-600);
}
.bg-zinc-800 { 
  background-color: var(--color-zinc-800);
}
.border-gray {
  border-color: #999;
}
.active {
  @apply bg-zinc-800;
}

.app-container {
    background-color: #f0f3f7
}
.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
  background-color: #2c2c2c !important; /* Your desired background color */
  /* border-top: none !important; */
}

input:-webkit-autofill,
input {
  /* -webkit-border: 1px solid white !important; */
  /* border: 1px solid white !important; */
  /* background-color: #2c2c2c; */
  /* color: white; */
}

/* input:-webkit-autofill:active, */
/* input:-webkit-autofill:focus, */
/* input:-webkit-autofill:hoverout { */
/*   border-top: none !important; */
/* } */
input:-webkit-autofill,
input {
  border-top: none !important; /* No border by default */
}

input:placeholder-shown {
  border-top: 1px solid white !important; /* Show the border when the placeholder is visible */
}

input:focus,
input:active {
  border-top: none !important; /* Remove border when the input is focused or active */
}
.peer:placeholder-shown~.peer-placeholder-shown\:after\:border-transparent:after, .peer:placeholder-shown~.peer-placeholder-shown\:before\:border-transparent:before {
    border-color: #e5e7eb!important;
    content: var(--tw-content);
}

/* Scrollbar styling */

html, body {
  background-color: rgb(64 64 64); /* App background color */
}

* {
  scrollbar-color: #646363 transparent; 
}